import type { NavigationGuardNext, RouteRecordRaw } from "vue-router";
import { createRouter, createWebHistory } from "vue-router";
import { goSignInPage } from "@/utils/commands";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import { useConfirmStore } from "@/stores/confirm";
import i18n from "@/plugins/vue-i18n";

const requireAuth =
  () => async (_to: unknown, _from: unknown, next: NavigationGuardNext) => {
    const { loggedIn } = useUserStore();
    if (!loggedIn) {
      next(false);
      return await goSignInPage();
    }
    return next();
  };

const checkChangedForm =
  () => async (_to: unknown, _from: unknown, next: NavigationGuardNext) => {
    const { formSaved } = storeToRefs(useConfirmStore());
    if (
      !formSaved.value &&
      !confirm(i18n.global.t("common.validation.moveConfirm"))
    ) {
      return next(false);
    }
    formSaved.value = true;
    return next();
  };

const routes = (): RouteRecordRaw[] => {
  const error: RouteRecordRaw[] = [
    {
      path: "",
      component: () => import("@/views/error/UnexpectedPage.vue"),
    },
    {
      path: "404",
      component: () => import("@/views/error/NotFoundPage.vue"),
    },
  ];

  const authPage: RouteRecordRaw[] = [
    {
      path: "request-reset-password",
      component: () => import("@/views/sign/RequestResetPassword.vue"),
    },
    {
      path: "reset-password",
      component: () => import("@/views/sign/ResetPassword.vue"),
    },
    {
      path: "sign-in",
      component: () => import("@/views/sign/in/SignIn.vue"),
    },
    {
      path: "sso",
      component: () => import("@/views/sign/in/SsoPass.vue"),
    },
  ];

  // 사입관리
  const purchase_manage: RouteRecordRaw[] = [
    {
      path: "take-in-code",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () =>
        import("@/views/purchase/take-in-code/TakeInCodePage.vue"),
    },
    {
      path: "take-in-order",
      beforeEnter: [requireAuth(), checkChangedForm()],

      component: () =>
        import("@/views/purchase/take-in-order/TakeInOrderPage.vue"),
    },
    {
      path: "shipping",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/purchase/shipping/ShippingPage.vue"),
    },
    {
      path: "inventory",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () =>
        import("@/views/purchase/inventory/TakeInInventoryPage.vue"),
    },
    {
      path: "take-in-order-etc",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () =>
          import("@/views/purchase/take-in-order-etc/TakeInOrderEtcPage.vue"),
    },

  ];

  // 검품
  const inspection: RouteRecordRaw[] = [
    {
      path: "list",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/inspection/list/InspectionPage.vue"),
    },
    {
      path: "newList",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/inspection/newList/InspectionPage.vue"),
    },
    {
      path: "inventory",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () =>
        import("@/views/inspection/inventory/InspectionInventoryPage.vue"),
    },
  ];

  // 운송장
  const waybill: RouteRecordRaw[] = [
    {
      path: "excel-registration",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () =>
        import(
          "@/views/waybill/excel-registration/WaybillExcelRegistrationPage.vue"
        ),
    },
    {
      path: "registration",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () =>
        import("@/views/waybill/registration/WaybillRegistrationPage.vue"),
    },
    {
      path: "print",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/waybill/print/WaybillPrintPage.vue"),
    },
    {
      path: "outgoing-confirmation",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () =>
        import("@/views/waybill/outgoing/WaybillOutgoingPage.vue"),
    },
    {
      path: "total-taxation-check",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () =>
        import("@/views/waybill/total-tax/WaybillTotalTaxPage.vue"),
    },
    {
      path: "removed",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () =>
        import("@/views/shipping/removed/ShippingRemovedPage.vue"),
    },
  ];

  // 운송장
  const shipping: RouteRecordRaw[] = [
    {
      path: "deleted-items",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () =>
        import("@/views/shipping/removed/ShippingRemovedPage.vue"),
    },
    {
      path: "list",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/shipping/ShippingPage.vue"),
    },
    {
      path: "commercial",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/shipping/commercial/CommercialPage.vue"),
    },
    {
      path: "daily",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/shipping/daily/ShippingDailyPage.vue"),
    },
  ];
  // 배송현황
  const delivery: RouteRecordRaw[] = [
    {
      path: "status",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/delivery/status/DeliveryStatusPage.vue"),
    },
    {
      path: "undelivered",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () =>
        import("@/views/delivery/undelivered/DeliveryUndeliveredPage.vue"),
    },
    {
      path: "vat",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () =>
        import("@/views/delivery/duty-surtax/DutySurtaxPage.vue"),
    },
    {
      path: "uncustoms",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/delivery/uncustoms/UncustomsPage.vue"),
    },
    {
      path: "delay",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/delivery/delay/DelayPage.vue"),
    },
  ];

  // 반품관리
  const return_manage: RouteRecordRaw[] = [
    {
      path: "management",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/return/management/ReturnPage.vue"),
    },
    {
      path: "receiving",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () =>
        import("@/views/return/receiving/ReturnReceivingPage.vue"),
    },
    {
      path: "unreceiving",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () =>
        import("@/views/return/unreceiving/ReturnUnreceivingPage.vue"),
    },
    {
      path: "shipping",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/return/shipping/ReturnShippingPage.vue"),
    },
  ];

  // 게시판
  const board: RouteRecordRaw[] = [
    {
      path: "qna",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/board/qna/QnaPage.vue"),
    },
    {
      path: "notice",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/board/notice/NoticePage.vue"),
    },
  ];

  // My-Page
  const myPage: RouteRecordRaw[] = [
    {
      path: "my-info",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/my-page/my-info/MyInfoPage.vue"),
    },
    {
      path: "excel-field",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/my-page/excel-field/ExcelFieldPage.vue"),
    },
    {
      path: "invoice",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/my-page/invoice/InvoicePage.vue"),
    },
  ];
  // FTA
  const fta: RouteRecordRaw[] = [
    {
      path: "fta",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/fta/FtaPage.vue"),
    },
  ];

  return [
    {
      path: "/",
      beforeEnter: [requireAuth(), checkChangedForm()],
      component: () => import("@/views/HomePage.vue"),
      meta: {
        layout: "default",
      },
    },
    {
      path: "/auth",
      children: authPage.map((v) => ({
        ...v,
        meta: {
          layout: "auth",
        },
      })),
    },
    {
      path: "/purchase",
      children: purchase_manage.map((v) => ({
        ...v,
        meta: {
          layout: v.path !== "inventory" ? "defaultForAutoHeight" : "default",
        },
      })),
      beforeEnter: [requireAuth(), checkChangedForm()],
    },
    {
      path: "/inspection",
      children: inspection.map((v) => ({
        ...v,
        meta: {
          layout: v.path === "list" ? "defaultForAutoHeight" : "default",
        },
      })),
      beforeEnter: [requireAuth(), checkChangedForm()],
    },
    {
      path: "/waybill",
      children: waybill.map((v) => ({
        ...v,
        meta: {
          layout:
            v.path === "excel-registration"
              ? "defaultForAutoHeight"
              : "default",
        },
      })),
      beforeEnter: [requireAuth(), checkChangedForm()],
    },
    {
      path: "/shipping",
      children: shipping.map((v) => ({
        ...v,
        meta: {
          layout: v.path !== "commercial" ? "defaultForAutoHeight" : "default",
        },
      })),
      beforeEnter: [requireAuth(), checkChangedForm()],
    },
    {
      path: "/delivery",
      children: delivery.map((v) => ({
        ...v,
        meta: {
          layout: "default",
        },
      })),
      beforeEnter: [requireAuth(), checkChangedForm()],
    },
    {
      path: "/board",
      children: board.map((v) => ({
        ...v,
        meta: {
          layout: "default",
        },
      })),
      beforeEnter: [requireAuth(), checkChangedForm()],
    },
    {
      path: "/my-page",
      children: myPage.map((v) => ({
        ...v,
        meta: {
          layout: "default",
        },
      })),
      beforeEnter: [requireAuth(), checkChangedForm()],
    },
    {
      path: "/fta",
      children: fta.map((v) => ({
        ...v,
        meta: {
          layout: "default",
        },
      })),
      beforeEnter: [requireAuth(), checkChangedForm()],
    },
    {
      path: "/return",
      children: return_manage.map((v) => ({
        ...v,
        meta: {
          layout: "default",
        },
      })),
      beforeEnter: [requireAuth(), checkChangedForm()],
    },
    {
      path: "/error",
      children: error.map((v) => ({
        ...v,
        meta: {
          layout: "error",
        },
      })),
    },
    {
      path: "/:catchAll(.*)", // Unrecognized path automatically matches 404,
      redirect: "/error/404",
    },
  ];
};

export default createRouter({
  history: createWebHistory(),
  routes: routes(),
});
