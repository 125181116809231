export default {
  ko: {
    cusReqDt: "요청일자",
    cusItemCode: "업체상품코드",
    itemBarcode: "상품바코드",
    netValue: "Net Value",
    wtaKg: "WT(A)/(kg)",
    wtcKg: "WT(C)/(kg)",
    puchStatus: "사용상태",
    whInCnt: "입고수량",
    whOutCnt: "출고수량",
    whInDate: "입고일자",
    cusOutNo: "업체출고번호",
    cusInvcNo: "업체 Invoice No.",
    cusSupplier: "Supplier",
    cusSupplierTel: "업체 Supplier 전화번호",
    cusSupplierAddr: "업체 Supplier 주소",
    inspector: "검수자",
    printLabelFlag: "라벨 프린트",
    etcInfo1: "추가정보1",
    etcInfo2: "추가정보2",
    etcInfo3: "추가정보3",
    inDate: "입고일자",

    totIn: "전체입고수량",
    totOut: "전체출고수량",
    totNotUse: "사용불가수량",
    totInventoryItem: "전체재고수량",
    whInventoryCnt: "전체재고수량",
    inventoryCnt: "재고수량",

    takeInNo: "상품번호",
    takeInStatus: "상품상태",

    realBuySite: "판매자 구매사이트",
    salesBroker: "판매중계업체명(플랫폼)",
    osSeller: "해외판매자명(해외직접수입형)",

    exRate: "환율",

    hawbNoDetailDialog: {
      title: "사입 운송장정보",
    },

    takeInCode: {
      takeInCode: "ACI 코드",
      manualButtonTitle: "등록요청 - 수기",
      excelButtonTitle: "등록요청 - 엑셀",
      removeButtonTitle: "삭제 요청",
      addSetItemTitle: "세트 상품 등록",
      setYn:"세트상품여부",

      removeConfirm: "선택한 사입상품코드 삭제요청 하시겠습니까?",

      editDialog: {
        title: "사입 상품코드",
        wtaKg: "Actual WT",
        wtcKg: "Volume WT",
        createConfirm: "상품코드 등록 하시겠습니까?",
        updateConfirm: "상품코드정보 저장겠습니까?",
      },
      excelDialog: {
        title: "사입상품코드 엑셀등록",
        confirmExcelUpload:
          "사입상품코드 등록 하시겠습니까?  (오류내역은 제외하고 저장됩니다.)",
      },
      bundleItemDialog: {
        addTitle: "세트상품 등록",
        editTitle: "세트상품 상세",
        bundleItemTitle: "세트 상품 정보",
        itemDetail:"상품명",
        cusItemCode:"업체상품코드",
        hsCode:"HS CODE",
        netValue:"Net Value",
        wtaKg:"Net W/T(kg)",
        wtcKg:"Volume (kg)",
        foodDivi:"식품구분",
        makeCountry:"제조국",
        makeCompany:"제조회사",
        takeInCodeList:"상품목록",
        takeInCodeNetVaule:"단가",
        totStock:"재고수량",
        itemCnt:"묶음수량",
        createConfirm: "세트 상품 등록 하시겠습니까?",
        updateConfirm: "세트 상품 수정 하시겠습니까?"
      },
    },

    takeInOrder: {
      excelItem: "엑셀다운로드(Item)",
    },

    shipping: {
      hawbDt: "출고 일자",
    },

    inventory: {
      tab: {
        receiveDailySummary: {
          title: "입고일자별집계",
        },
        detail: {
          title: "상세내역",
          filterUseYn: "상품상태",
        },
      },
    },
    etc :{
      dialogTitle : "기타출고 출고처리",
      addDialogTitle : "기타출고 등록",
      editDialogTitle : "기타출고 상세",
      shipmentDialogTitle : "기타출고 출고처리",
      takeInStatus: "사용상태",
      orderDate:"출고요청일",
      trkNo: "택배번호",
      trkCom: "택배회사",
      reqCnt: "출고요청수량",
      outCnt: "출고수량",
      adjustFee: "청구비용",
      currency: "청구통화",
      invcStation: "청구스테이션",
      remark: "내용",
      cancelOutgoing: "출고취소",
      outgoing : "출고처리",
      etcInfo : "기타출고 정보",
    }
  },
  en: {},
  zhHans: {},
};
